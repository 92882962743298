import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import defaultImage from '../../static/me.jpg'

import appleIcon from '../../static/apple-touch-icon.png'
import icon32 from '../../static/favicon-32x32.png'
import icon16 from '../../static/favicon-16x16.png'

export default ({title, description, image}) => {
    const data = useStaticQuery(graphql`
        query seo {
            site {
                siteMetadata {
                    title
                    keywords
                    description
                    siteUrl
                }
            }
        }      
    `)
    const meta = data.site.siteMetadata;

    const ttl = title ? `${title} | ${meta.title}`  : meta.title;
    const desc = description ? description : meta.description;
    const img = image ? image : defaultImage;
    return (
        <Helmet>
            <html lang="en"/>
            <meta charSet="utf-8"/>
            <link rel="preconnect" href="https://fonts.gstatic.com"/>
            <link href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald&display=swap" rel="stylesheet"/>
            <script src="https://kit.fontawesome.com/ff0e897b34.js" crossorigin="anonymous"></script>

            <title>{ttl}</title>
            <meta name="description" content={desc} />
            <meta name="keywords" content={meta.keywords}/> 

            {/* FavIcons */}
            <link rel="apple-touch-icon" sizes="180x180" href={appleIcon}/>
            <link rel="icon" type="image/png" sizes="32x32" href={icon32}/>
            <link rel="icon" type="image/png" sizes="16x16" href={icon16}/>
            <meta name="msapplication-TileColor" content="#ffffff"/>
            <meta name="theme-color" content="#ffffff"/>

            {/* OpenGraph tags */}
            <meta property="og:title" content={ttl}/>
            <meta property="og:description" content={desc}/>
            <meta property="og:image" content={img}/>
            <meta property="og:url" content={meta.siteUrl}/>

            {/* Twitter tags */}
            <meta name="twitter:title" content={ttl}/>
            <meta name="twitter:description" content={desc}/>
            <meta name="twitter:image" content={img}/>
            <meta name="twitter:card" content={meta.siteUrl}/>

        </Helmet>
    )

}