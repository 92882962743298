import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import './style.css'
export default function SocialMedia() {
    const data = useStaticQuery(graphql`
    query HeaderQuery {
        contentfulContacts {
            email
            instagram
            behance
        }
    }
  `)

    const links = data.contentfulContacts;
    return (
        <div className="social-media">
            {/* <a href={links.behance} className="social-media--icon social-media--icon_behance"><i className="fab fa-behance"></i></a> */}
            <a href={`https://api.whatsapp.com/send?phone=393668202223`} className="social-media--icon social-media--icon_whatsapp">
                <i class="fab fa-whatsapp"/>
            </a>
            <a href={`mailto:${links.email}`} className="social-media--icon social-media--icon_email"><i className="far fa-envelope"></i></a>
            <a href={links.instagram} className="social-media--icon social-media--icon_instagram"><i className="fab fa-instagram"></i></a>
        </div>
    )
}