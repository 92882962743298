import React, { useState } from 'react'
import { Link } from 'gatsby'
import * as navStyles from './style.module.css'

export default ({ items, mobile }) => {
    
    const [openedSection, handleDropdown] = useState('');
    function openSection(section) {
        if (openedSection !== section)
            handleDropdown(section)
        else 
            handleDropdown('')
    }

    function checkCurrentPage(section) {
        const url = typeof window !== 'undefined' ? window.location.href : '';
        
        return url.includes(section)
    }


    // console.log(openedSection, 'OPENED_SECTIOn')

    return (
        <nav className={mobile ? navStyles.containerMobile : navStyles.container}>
        <ul className={navStyles.ul}>
            <li className={`${navStyles.li} ${navStyles.navItem}`}>
                <Link
                    to={'/' + items.contentfulAbout.slug}
                    className={navStyles.link}
                    activeClassName={navStyles.linkActive}
                >
                    {items.contentfulAbout.title}
                </Link>
            </li>
            <li className={`${navStyles.li} ${navStyles.navItem}`}>
                <Link
                    to={'/' + items.contentfulContacts.slug}
                    className={navStyles.link}
                    activeClassName={navStyles.linkActive}
                >
                    {items.contentfulContacts.title}
                </Link>
            </li>

            {
                items.allContentfulSection.nodes.map((item, index) => {
                    return (
                        <li 
                        className={navStyles.li}
                        key={`${item.title}-${index}`}
                        >
                            <button
                                id={item.title}
                                className={`${navStyles.dropdown} ${navStyles.navItem}`}
                                onClick={() => openSection(item.title)}
                            >
                                {item.title}
                                <svg className={(openedSection === item.title || checkCurrentPage(item.title.toLowerCase())) ? `${navStyles.arrow} ${navStyles.arrow_up}` : navStyles.arrow} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492"><path d="M484.1 125L468 108.8c-5.1-5.1-11.8-7.9-19-7.9-7.2 0-14 2.8-19 7.9L246.1 292.6l-184-184c-5.1-5.1-11.8-7.9-19-7.9s-14 2.8-19 7.9L7.9 124.7c-10.5 10.5-10.5 27.6 0 38.1L227 382.7c5.1 5.1 11.8 8.6 19.1 8.6h.1c7.2 0 14-3.6 19-8.6l218.9-219.3c5.1-5.1 7.9-12 7.9-19.2 0-7.3-2.8-14.2-7.9-19.2z" fill="#575353"/></svg>
                            </button>
                            <ul  
                            id={`${item.title}-${index}-list`}
                            className={(openedSection === item.title || checkCurrentPage(item.title.toLowerCase())) ? `${navStyles.ul} ${navStyles.subUl}`  : `${navStyles.ul} ${navStyles.subUl} ${navStyles.ulClosed}`}
                            >
                                {/*  */}
                                {
                                    item.projects.map((project) => {
                                        return (
                                            <li key={project.title} className={`${navStyles.li} ${navStyles.navItem}`}>
                                                <Link
                                                    to={'/' + item.slug + '/' + project.slug}
                                                    className={navStyles.link}
                                                    activeClassName={navStyles.linkActive}
                                                >
                                                {project.title}
                                                </Link>
                                            </li>
                                                )
                                            })
                                        }
                            </ul>
                        </li>
                    )
                })
            }

            {/* {
                items.allContentfulSection.nodes.map((item, index) => {
                    return (
                        <li key={item + index} className={navStyles.li}>
                            <Accordion
                                // allowMultipleExpanded = {true}
                                allowZeroExpanded = {true}
                            >
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton
                                        className={`${navStyles.dropdown} ${navStyles.navItem}`} 
                                        >
                                            {item.title}
                                            <svg className={isOpened ? `${navStyles.arrow} ${navStyles.arrow_up}` : navStyles.arrow} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492"><path d="M484.1 125L468 108.8c-5.1-5.1-11.8-7.9-19-7.9-7.2 0-14 2.8-19 7.9L246.1 292.6l-184-184c-5.1-5.1-11.8-7.9-19-7.9s-14 2.8-19 7.9L7.9 124.7c-10.5 10.5-10.5 27.6 0 38.1L227 382.7c5.1 5.1 11.8 8.6 19.1 8.6h.1c7.2 0 14-3.6 19-8.6l218.9-219.3c5.1-5.1 7.9-12 7.9-19.2 0-7.3-2.8-14.2-7.9-19.2z" fill="#575353"/></svg>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <ul className={`${navStyles.ul} ${navStyles.subUl}` }>
                                        {
                                            
                                            item.projects.map((project) => {
                                                return (
                                                    <li key={project} className={`${navStyles.li} ${navStyles.navItem}`}>
                                                        <Link
                                                            to={'/' + item.slug + '/' + project.slug}
                                                            className={navStyles.link}
                                                            activeClassName={navStyles.linkActive}
                                                        >
                                                            {project.title}
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                            {/* <button
                                className={`${navStyles.dropdown} ${navStyles.navItem}`} 
                                onClick={() => {handleDropdown(!isOpened)}}>
                                {item.title}
                                <svg className={isOpened ? `${navStyles.arrow} ${navStyles.arrow_up}` : navStyles.arrow} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492"><path d="M484.1 125L468 108.8c-5.1-5.1-11.8-7.9-19-7.9-7.2 0-14 2.8-19 7.9L246.1 292.6l-184-184c-5.1-5.1-11.8-7.9-19-7.9s-14 2.8-19 7.9L7.9 124.7c-10.5 10.5-10.5 27.6 0 38.1L227 382.7c5.1 5.1 11.8 8.6 19.1 8.6h.1c7.2 0 14-3.6 19-8.6l218.9-219.3c5.1-5.1 7.9-12 7.9-19.2 0-7.3-2.8-14.2-7.9-19.2z" fill="#575353"/></svg>
                            </button>
                            <ul className={isOpened ? `${navStyles.ul} ${navStyles.subUl}` : `${navStyles.ul} ${navStyles.subUl} ${navStyles.ulClosed}`}>
                                {
                                    
                                    item.projects.map((project) => {
                                        return (
                                            <li className={`${navStyles.li} ${navStyles.navItem}`}>
                                                <Link
                                                    to={'/' + item.slug + '/' + project.slug}
                                                >
                                                    {project.title}
                                                </Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul> */}
                        {/* </li> */}
        </ul>
        </nav>
    )
}