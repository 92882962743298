import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from "styled-components"
import SiteNav from './SiteNav'

import '../styles/hamburgers.css'

const HeaderWrapper = styled.header`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    /* height: 100%; */
    box-sizing: border-box;
    /* width: 1000px; */
    width: 15%;
    min-width: 160px;
    /* margin-bottom: 30px; */
    /* height: 90vh; */
    /* min-height: 800px; */
    @media (max-height: 800px) {
        margin-bottom: 30px;
    }
    @media (max-width: 600px) {
        display: none;
    }
`;

const SiteLogo = styled(Link)`
    font-family: 'Oswald', sans-serif;
    color: black;
    font-size: 20px;
    margin-top: -8px;
`;

const HeaderSubWrapper = styled.div`
    width: 100%;
`;

const MobileHeader = styled.header`
    display: block;
    margin-bottom: 30px;
    @media (min-width: 600px) {
        display: none;
    }
`;
const MobileHeaderWrapper = styled.div `
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const MobileNav = styled.div `
    /* text-align: right; */
    display: ${props => props.isopened.closed};
    /* margin-right: 24px; */
    /* float: right; */
    margin-bottom: 24px;
`;

MobileNav.defaultProps = {
    isopened: {
        closed: "none"
    }
}

const opened = {
    closed: "block"
};

const closed = {
    closed: "none"
}
export default ({location}) => {
    const [isOpened, openNav] = useState(false);
    const toggleNav = () => {openNav(!isOpened)}

    const siteTitle = 'Angelina Garipova';
    const data = useStaticQuery(graphql`
        query headerQuery {
            contentfulContacts {
                title
                slug
            }
            contentfulAbout {
                title
                slug
            }
            allContentfulSection(sort: {fields: order}) {
                nodes {
                    title
                    slug
                    projects {
                        title
                        slug
                    }
                }
            }
        }      
    `)

    return (
        <>
            <HeaderWrapper>
                <HeaderSubWrapper>
                    <SiteLogo
                        to={'/'}
                    >
                        {siteTitle}
                    </SiteLogo>
                    <SiteNav 
                        items = {data}
                        location = {location}
                        
                    />
                </HeaderSubWrapper>


            </HeaderWrapper>

            <MobileHeader>
                    <MobileHeaderWrapper>
                    <SiteLogo
                        to={'/'}
                    >
                        {siteTitle}
                    </SiteLogo>
                
                    <button 
                        className={isOpened ? "hamburger hamburger--spin is-active" : "hamburger hamburger--spin"} 
                        type="button"
                        onClick={toggleNav}
                        >
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                    </MobileHeaderWrapper>
                <MobileNav isopened={isOpened? opened : closed}>
                    <SiteNav 
                        items = {data}
                        location = {location}
                        mobile
                    />
                </MobileNav>
            </MobileHeader>
        </>
    )
}

// <SocialMedia/>