import React from 'react'
import SocialMedia from './SocialMedia'
import styled from "styled-components"

const Footer = styled.footer`
    display: ${props => props.theme.main};
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

`;

Footer.defaultProps = {
    theme: {
      main: "block"
    }
  }

  const theme = {
    main: "none"
  };

const Container = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
@media (max-width: 1470px) {
    margin-left: 30px;
    margin-right: 30px;
}
@media (max-width: 1100px) {
        margin-left: 15px;
        margin-right: 15px;
}
`;
export default ({about}) => {
    console.log(about, 'ABOUT')
    return (
        <Footer theme={about ? theme : ''}>
            <Container>
                <SocialMedia/>
            </Container>
            
        </Footer>
    )
}