import React from 'react'

import Footer from './Footer'
import Header from './Header'
import Seo from './Seo'
import styled from "styled-components"
import '../styles/global.css'
import 'swiper/swiper-bundle.min.css';

import photo from '../../static/about.jpg';

const DesktopPageWrapper = styled.div`
    padding-top: 60px;
    display: flex;
    flex-direction: row;
    /* height: 90vh; */
    /* width: 100%; */
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    /* height: 100vh; */
    min-height: 82vh;
    position: relative;


    @media (max-width: 600px) {
        flex-direction: column;
        padding-top: 10px;
    }
    @media (max-width: 1470px) {
        margin-left: 30px;
        margin-right: 30px;
}
@media (max-width: 1100px) {
        margin-left: 15px;
        margin-right: 15px;
}
`;

const Content = styled.main`
    width: 75%;
    
    @media (max-width: 600px) {
        width: 100%;
    }
`;

class Layout extends React.Component {
    
    render() {
        const { children } = this.props;
        return (
            <>
                <Seo/>
                <div className={this.props.about ? 'bg bg-photo' : 'bg'}>
                    <DesktopPageWrapper>
                        
                        <Header />
                            <Content>
                                {children}
                            </Content>
                    </DesktopPageWrapper>
                </div>
                <Footer about={this.props.about}/>
            </>
        )
    }
}

export default Layout